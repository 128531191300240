/*
Name: 			theme-elements.css
Written by: 	Okler Themes - (http://www.okler.net)
Version: 		3.5.1
*/
/* Headings */
h1, h2, h3, h4, h5, h6 {
	color: #0e0e0e;
	font-weight: 200;
	letter-spacing: -1px;
	margin: 0;
}

h1 {
	font-size: 2.6em;
	line-height: 44px;
	margin: 0 0 44px 0;
}

h2 {
	font-size: 2.2em;
	font-weight: 300;
	line-height: 42px;
	margin: 0 0 32px 0;
}

h3 {
	color: #CCC;
	font-size: 1.8em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 24px;
	margin-bottom: 33px;
	text-transform: uppercase;
}

h4 {
	color: #CCC;
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 27px;
	margin: 0 0 14px 0;
}

h5 {
	color: #CCC;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
	text-transform: uppercase;
}

h6 {
	color: #333;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
}

h1.big {
	font-size: 4.2em;
	line-height: 54px;
}

h1.short {
	margin-bottom: 15px;
}

h2.short {
	margin-bottom: 15px;
}

h3.short,
h4.short,
h5.short,
h6.short {
	margin-bottom: 9px;
}

h1.shorter,
h2.shorter,
h3.shorter,
h4.shorter,
h5.shorter,
h6.shorter {
	margin-bottom: 0;
}

h1.tall,
h2.tall,
h3.tall,
h4.tall,
h5.tall,
h6.tall {
	margin-bottom: 33px;
}

h1.taller,
h2.taller,
h3.taller,
h4.taller,
h5.taller,
h6.taller {
	margin-bottom: 44px;
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
	margin-top: 22px;
}

h1.more-spaced,
h2.more-spaced,
h3.more-spaced,
h4.more-spaced,
h5.more-spaced,
h6.more-spaced {
	margin-top: 44px;
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
	color: #0e0e0e;
}

h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white {
	color: #FFF;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Modal Titles */
.modal-header h3 {
	color: #333;
	text-transform: none;
}

/* Responsive */
@media (max-width: 479px) {
	h2 {
		line-height: 40px;
	}
}

@media (max-width: 767px) {
	h1.big {
		font-size: 3.2em;
		line-height: 42px;
	}
}
/* Blockquote */
blockquote {
	font-size: 1em;
}

/* Paragraphs */
p {
	color: #777777;
	line-height: 24px;
	margin: 0 0 20px;
}

p.featured {
	font-size: 1.6em;
	line-height: 1.5em;
}

p.short {
	margin-bottom: 0;
}

p.tall {
	margin-bottom: 20px;
}

p.taller {
	margin-bottom: 40px;
}

p .alternative-font {
	display: inline-block;
	margin-top: -15px;
	position: relative;
	top: 3px;
	margin-bottom: -6px;
}

a, a:hover, a:focus {
	color: #CCC;
}

/* Labels */
.label-primary {
	background-color: #CCC;
}

.label-dark,
.label-dark:hover {
	background: #111;
	text-decoration: none;
	font-weight: normal;
}

/* Drop Caps */
p.drop-caps:first-child:first-letter {
	float: left;
	font-size: 75px;
	line-height: 60px;
	padding: 4px;
	margin-right: 5px;
	margin-top: 5px;
	font-family: Georgia;
}

p.drop-caps.secundary:first-child:first-letter {
	background-color: #CCC;
	color: #FFF;
	padding: 6px;
	margin-right: 5px;
	border-radius: 4px;
}

/* Alternative Font Style */
.alternative-font {
	color: #CCC;
	font-family: "Shadows Into Light", cursive;
	font-size: 1.6em;
}

/* Navs */
ul.nav-list.primary > li {
	margin: 0;
	padding: 0;
}

ul.nav-list.primary > li a {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background-image: url("../img/list-primary.png");
	background-position: 9px 16px;
	background-repeat: no-repeat;
	border-bottom: 1px solid #EDEDDE;
	padding: 8px 20px;
}

ul.nav-pills > li.active > a {
	background-color: #CCC;
}

ul.nav-pills > li.active > a:hover, ul.nav-pills > li.active > a:focus {
	background-color: #CCC;
}

/* Buttons */
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
	background-color: #CCC;
	border-color: #CCC;
}

.btn-primary[disabled] {
	border-color: #CCC !important;
	background-color: #CCC;
	background-image: none;
}

.btn-icon i {
	margin-right: 10px;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 10px;
}

/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	border-color: #CCC;
}

/* Forms Validations */
label.valid {
	display: inline-block;
	text-indent: -9999px;
}

label.error {
	color: #C10000;
	font-size: 0.9em;
	margin-top: -5px;
	padding: 0;
}

label.error#captcha-error {
	margin-top: 15px;
}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
}

.captcha input {
	border: 0;
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: left;
	position: relative;
	top: -5px;
	left: -13px;
}

/* Icons */
.featured-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.featured-icon i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Icons List */
ul.icons {
	list-style: none;
}

ul.icons [class^="fa-"] {
	margin-right: 5px;
}

/* Font Awesome List */
.fontawesome-icon-list [class*="col-md-"] {
	margin-bottom: 9px;
}

.fontawesome-icon-list > section {
	margin-top: 25px;
}

/* Thumbnails */
.img-thumbnail {
	border-radius: 8px;
	position: relative;
}

.img-thumbnail .zoom {
	background: #CCC;
	border-radius: 100%;
	bottom: 8px;
	color: #FFF;
	display: block;
	height: 30px;
	padding: 6px;
	position: absolute;
	right: 8px;
	text-align: center;
	width: 30px;
}

.img-thumbnail .zoom i {
	font-size: 14px;
	left: -1px;
	position: relative;
	top: -3px;
}

/* Thumbnail Gallery */
ul.thumbnail-gallery {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}

ul.thumbnail-gallery li {
	display: inline-block;
	margin: 10px 10px 0 0;
	padding: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.thumbnail {
		border: 0;
		text-align: center;
	}
}
/* List */
ul.list li {
	margin-bottom: 13px;
}

ul.list.icons li i {
	color: #CCC;
	margin-right: 5px;
}

ul.list.pull-left li {
	text-align: left;
}

/* Sitemap */
ul.sitemap > li > a {
	font-weight: bold;
}

ul.sitemap.list li {
	margin: 0 0 3px 0;
}

ul.sitemap.list ul {
	margin-bottom: 5px;
	margin-top: 5px;
}

/* Accordion */
.panel-group .panel-heading {
	padding: 0;
	border-radius: 3px;
}

.panel-group .panel-heading a {
	display: block;
	padding: 10px 15px;
}

.panel-group .panel-heading a:hover, .panel-group .panel-heading a:focus {
	text-decoration: none;
}

.panel-group .panel-heading a [class^="icon-"] {
	margin-right: 4px;
	position: relative;
	top: 1px;
}

.panel-group.secundary .panel-heading a {
	color: #FFF;
}

/* Tabs */
.tabs {
	border-radius: 4px;
	margin-bottom: 35px;
}

.nav-tabs {
	margin: 0;
	font-size: 0;
	border-bottom-color: #EEE;
}

.nav-tabs li {
	display: inline-block;
	float: none;
}

.nav-tabs li:last-child a {
	margin-right: 0;
}

.nav-tabs li a {
	border-radius: 5px 5px 0 0;
	font-size: 14px;
	margin-right: 1px;
}

.nav-tabs li a, .nav-tabs li a:hover {
	background: #F4F4F4;
	border-bottom: none;
	border-left: 1px solid #EEE;
	border-right: 1px solid #EEE;
	border-top: 3px solid #EEE;
	color: #CCC;
}

.nav-tabs li a:hover {
	border-bottom-color: transparent;
	border-top: 3px solid #CCC;
	box-shadow: none;
}

.nav-tabs li a:active, .nav-tabs li a:focus {
	border-bottom: 0;
}

.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top: 3px solid #CCC;
	color: #CCC;
}

.tab-content {
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	background-color: #FFF;
	border: 1px solid #EEE;
	border-top: 0;
	padding: 15px;
}

/* Right Aligned */
.nav-tabs.nav-right {
	text-align: right;
}

/* Bottom Tabs */
.tabs.tabs-bottom .tab-content {
	border-radius: 4px 4px 0 0;
	border-bottom: 0;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs {
	border-bottom: none;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li {
	margin-bottom: 0;
	margin-top: -1px;
}

.tabs.tabs-bottom .nav-tabs li:last-child a {
	margin-right: 0;
}

.tabs.tabs-bottom .nav-tabs li a {
	border-radius: 0 0 5px 5px;
	font-size: 14px;
	margin-right: 1px;
}

.tabs.tabs-bottom .nav-tabs li a, .tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #EEE;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #CCC;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li.active a,
.tabs.tabs-bottom .nav-tabs li.active a:hover,
.tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom: 3px solid #CCC;
	border-top-color: transparent;
}

/* Vertical */
.tabs-vertical {
	display: table;
	width: 100%;
	border-top: 1px solid #EEE;
}

.tabs-vertical .tab-content {
	display: table-cell;
	vertical-align: top;
}

.tabs-vertical .nav-tabs {
	border-bottom: none;
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	vertical-align: top;
}

.tabs-vertical .nav-tabs > li {
	display: block;
}

.tabs-vertical .nav-tabs > li a {
	border-radius: 0;
	display: block;
	padding-top: 10px;
}

.tabs-vertical .nav-tabs > li a, .tabs-vertical .nav-tabs > li a:hover, .tabs-vertical .nav-tabs > li a:focus {
	border-bottom: none;
	border-top: none;
}

.tabs-vertical .nav-tabs > li.active a,
.tabs-vertical .nav-tabs > li.active a:hover, .tabs-vertical .nav-tabs > li.active:focus {
	border-top: none;
}

/* Vertical - Left Side */
.tabs-left .tab-content {
	border-radius: 0 5px 5px 5px;
	border-left: none;
}

.tabs-left .nav-tabs > li {
	margin-right: -1px;
}

.tabs-left .nav-tabs > li:first-child a {
	border-radius: 5px 0 0 0;
}

.tabs-left .nav-tabs > li:last-child a {
	border-radius: 0 0 0 5px;
	border-bottom: 1px solid #eee;
}

.tabs-left .nav-tabs > li a {
	border-right: 1px solid #EEE;
	border-left: 3px solid #EEE;
	margin-right: 1px;
	margin-left: -3px;
}

.tabs-left .nav-tabs > li a:hover {
	border-left-color: #CCC;
}

.tabs-left .nav-tabs > li.active a,
.tabs-left .nav-tabs > li.active a:hover,
.tabs-left .nav-tabs > li.active a:focus {
	border-left: 3px solid #CCC;
	border-right-color: #FFF;
}

/* Vertical - Right Side */
.tabs-right .tab-content {
	border-radius: 5px 0 5px 5px;
	border-right: none;
}

.tabs-right .nav-tabs > li {
	margin-left: -1px;
}

.tabs-right .nav-tabs > li:first-child a {
	border-radius: 0 5px 0 0;
}

.tabs-right .nav-tabs > li:last-child a {
	border-radius: 0 0 5px 0;
	border-bottom: 1px solid #eee;
}

.tabs-right .nav-tabs > li a {
	border-right: 3px solid #EEE;
	border-left: 1px solid #EEE;
	margin-right: 1px;
	margin-left: 1px;
}

.tabs-right .nav-tabs > li a:hover {
	border-right-color: #CCC;
}

.tabs-right .nav-tabs > li.active a,
.tabs-right .nav-tabs > li.active a:hover,
.tabs-right .nav-tabs > li.active a:focus {
	border-right: 3px solid #CCC;
	border-left: 1px solid #FFF;
}

/* Justified */
.nav-tabs.nav-justified {
	margin-bottom: -1px;
}

.nav-tabs.nav-justified li {
	margin-bottom: 0;
}

.nav-tabs.nav-justified li:first-child a,
.nav-tabs.nav-justified li:first-child a:hover {
	border-radius: 5px 0 0 0;
}

.nav-tabs.nav-justified li:last-child a,
.nav-tabs.nav-justified li:last-child a:hover {
	border-radius: 0 5px 0 0;
}

.nav-tabs.nav-justified li a {
	border-bottom: 1px solid #DDD;
	border-radius: 0;
	margin-right: 0;
}

.nav-tabs.nav-justified li a:hover, .nav-tabs.nav-justified li a:focus {
	border-bottom: 1px solid #DDD;
}

.nav-tabs.nav-justified li.active a,
.nav-tabs.nav-justified li.active a:hover,
.nav-tabs.nav-justified li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a {
	border-bottom: 1px solid #FFF;
}

.nav-tabs.nav-justified li.active a, .nav-tabs.nav-justified li.active a:hover, .nav-tabs.nav-justified li.active a:focus {
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a:hover {
	border-bottom: 1px solid #FFF;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
	border-top: none;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
	margin-right: 0;
	border-top-color: transparent;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a {
	border-radius: 0 0 0 5px;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a {
	margin-right: 0;
	border-radius: 0 0 5px 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:focus {
	border-top-color: transparent;
}

/* Responsive */
@media (max-width: 479px) {
	.tabs .nav.nav-tabs.nav-justified li {
		display: block;
		margin-bottom: -5px;
	}

	.tabs .nav.nav-tabs.nav-justified li a {
		border-top-width: 3px !important;
		border-bottom-width: 0 !important;
	}

	.tabs .nav.nav-tabs.nav-justified li:first-child a, .tabs .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 5px 5px 0 0;
	}

	.tabs .nav.nav-tabs.nav-justified li:last-child a, .tabs .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
		margin-bottom: 0;
		margin-top: -5px;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
		border-bottom-width: 3px !important;
		border-top-width: 0 !important;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0 0 5px 5px;
	}
}
/* Toggles */
.toggle {
	margin: 10px 0 0;
	position: relative;
	clear: both;
}

.toggle > input {
	cursor: pointer;
	filter: alpha(opacity=0);
	height: 45px;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 2;
}

.toggle > label {
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
	background: #F4F4F4;
	border-left: 3px solid #CCC;
	border-radius: 5px;
	color: #CCC;
	display: block;
	font-size: 1.1em;
	min-height: 20px;
	padding: 12px 20px 12px 10px;
	position: relative;
	cursor: pointer;
	font-weight: 400;
}

.toggle > label:-moz-selection {
	background: none;
}

.toggle > label i.fa-minus {
	display: none;
}

.toggle > label i.fa-plus {
	display: inline;
}

.toggle > label:selection {
	background: none;
}

.toggle > label:before {
	border: 6px solid transparent;
	border-left-color: inherit;
	content: '';
	margin-top: -6px;
	position: absolute;
	right: 4px;
	top: 50%;
}

.toggle > label:hover {
	background: #f5f5f5;
}

.toggle > label + p {
	color: #999;
	display: block;
	overflow: hidden;
	padding-left: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 25px;
}

.toggle > label i {
	font-size: 0.7em;
	margin-right: 8px;
	position: relative;
	top: -1px;
}

.toggle > .toggle-content {
	display: none;
}

.toggle > .toggle-content > p {
	margin-bottom: 0;
	padding: 10px 0;
}

.toggle.active i.fa-minus {
	display: inline;
}

.toggle.active i.fa-plus {
	display: none;
}

.toggle.active > label {
	background: #F4F4F4;
	border-color: #CCC;
}

.toggle.active > label:before {
	border: 6px solid transparent;
	border-top-color: #FFF;
	margin-top: -3px;
	right: 10px;
}

.toggle.active > p {
	white-space: normal;
}

.toggle > p.preview-active {
	height: auto;
	white-space: normal;
}

/* Owl Carousel */
.owl-carousel {
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-wrapper-outer {
	border-radius: 4px;
}

.owl-carousel .thumbnail {
	max-width: 99%;
}

.owl-carousel .img-responsive {
	display: inline-block;
}

.owl-carousel-spaced {
	margin-left: -5px;
}

.owl-carousel-spaced .owl-item > div {
	margin: 5px;
}

.owl-theme .owl-controls {
	margin-top: 20px;
}

.owl-theme .owl-controls .owl-buttons div {
	border-radius: 4px;
	border-bottom: 2px solid transparent;
	filter: alpha(opacity=100);
	opacity: 1;
	padding: 1px 9px;
	margin: 2px;
}

/* Owl Carousel - Highlight */
.highlight .owl-theme .owl-controls .owl-page span {
	background-color: #FFF;
}

/* Responsive */
@media (max-width: 479px) {
	.owl-carousel-spaced {
		margin-left: 0;
	}
}
/* Video */
section.video {
	-webkit-transform: translate3d(0, 0, 0);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	margin: 70px 0;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
}

section.video blockquote {
	border: 0;
	padding: 0 10%;
}

section.video blockquote i.fa-quote-left {
	font-size: 34px;
	position: relative;
	left: -5px;
}

section.video blockquote p {
	font-size: 24px;
	line-height: 30px;
}

section.video blockquote span {
	font-size: 16px;
	line-height: 20px;
	color: #999;
	position: relative;
	left: -5px;
}

section.video .container {
	position: relative;
	z-index: 3;
}

section.video .video-overlay {
	background: transparent url("../img/video-overlay.png") repeat 0 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

html.boxed section.video > div:first-child {
	z-index: 0 !important;
}

html.boxed section.video > div:first-child video {
	z-index: 0 !important;
}

/* Popup */
.popup-inline-content {
	background: none repeat scroll 0 0 #FFF;
	margin: 40px auto;
	max-width: 1170px;
	padding: 20px 30px;
	position: relative;
	text-align: left;
	display: none;
}

/* Magnific Popup */
.mfp-wrap .popup-inline-content {
	display: block;
}

/* Miscellaneous */
body a {
	outline: none !important;
}

li {
	line-height: 24px;
}

.center {
	text-align: center;
}

.push-bottom {
	margin-bottom: 35px;
}

.push-top {
	margin-top: 35px;
}

.bold {
	font-weight: bold;
}

.inverted {
	color: #FFF;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}

h1 .inverted {
	padding-left: 10px;
	padding-right: 10px;
}

h2 .inverted {
	padding-left: 7px;
	padding-right: 7px;
}

h3 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h4 .inverted {
	padding-left: 4px;
	padding-right: 4px;
}

h5 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h6 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

/* Clearfix */
.clearfix {
	display: inline-block;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

/* Pagination */
.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #CCC;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #CCC;
	border-color: #CCC;
}

/* Divider Line */
hr {
	background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
	border: 0;
	height: 1px;
	margin: 22px 0 22px 0;
}

hr.short {
	margin: 11px 0 11px 0;
}

hr.tall {
	margin: 44px 0 44px 0;
}

hr.taller {
	margin: 66px 0 66px 0;
}

hr.light {
	background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
}

hr.invisible {
	background: none;
}

/* Read More */
.read-more,
.learn-more {
	display: inline-block;
	white-space: nowrap;
}

/* Offset Anchor */
.offset-anchor {
	display: block;
	position: relative;
	visibility: hidden;
	top: -100px;
}

/* Responsive */
@media (max-width: 991px) {
	.offset-anchor {
		top: 0;
	}
}
/* Show Grid */
.show-grid [class*="col-md-"] .show-grid-block {
	background-color: #EEE;
	line-height: 40px;
	min-height: 40px;
	text-align: center;
	border: 1px solid #FFF;
	display: block;
}

/* Font Awesome */
.fontawesome-icon-list [class*="col-md-"] {
	margin-bottom: 9px;
}

.fontawesome-icon-list i {
	font-size: 1.3em;
	margin-right: 5px;
	display: inline-block;
	width: 30px;
	text-align: center;
	position: relative;
	top: 2px;
}

/* Alert Admin */
.alert-admin {
	margin: 25px 0;
}

.alert-admin img {
	margin: -50px 0 0;
}

.alert-admin .btn {
	margin: 0 0 -15px;
}

.alert-admin p {
	color: #444;
}

.alert-admin h4 {
	color: #111;
	font-size: 1.2em;
	font-weight: 600;
	text-transform: uppercase;
}

.alert-admin .warning {
	color: #B20000;
}

/* Sort Source Wrapper */
.sort-source-wrapper {
	padding: 8px 0;
	position: relative;
}

.sort-source-wrapper .nav {
	margin: -100px 0 5px;
}

.sort-source-wrapper .nav > li > a {
	color: #FFF;
	background: transparent;
}

.sort-source-wrapper .nav > li > a:hover, .sort-source-wrapper .nav > li > a:focus {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a:hover, .sort-source-wrapper .nav > li.active > a:focus {
	background: transparent;
}

/* Sort Source */
.sort-source {
	clear: both;
}

/* Sort Destination */
.sort-destination.full-width {
	position: relative;
}

.sort-destination.full-width .isotope-item {
	width: 20%;
	float: left;
}

.sort-destination.full-width .isotope-item.w2 {
	width: 40%;
}

.sort-destination.full-width .isotope-item .img-thumbnail {
	border: 0;
	padding: 0;
	margin: 0;
}

.sort-destination.full-width .isotope-item .img-thumbnail, .sort-destination.full-width .isotope-item a, .sort-destination.full-width .isotope-item .thumb-info img {
	border-radius: 0;
	float: left;
}

.sort-source-wrapper + .sort-destination.full-width {
	margin: 0 0 -81px;
	top: -51px;
}

/* Responsive */
@media (max-width: 991px) {
	ul.sort-destination.isotope {
		overflow: visible !important;
		height: auto !important;
	}

	ul.sort-destination.isotope.full-width {
		overflow: hidden !important;
	}

	.isotope-hidden {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.sort-source-wrapper {
		background: #171717;
		clear: both;
		margin-top: -35px;
	}

	.sort-source-wrapper .nav {
		margin: 0;
	}

	.sort-destination {
		height: auto !important;
	}

	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width {
		max-width: none;
		top: 0;
		margin-bottom: -30px;
	}

	.sort-destination.full-width .isotope-item {
		max-width: none;
	}

	.isotope-item {
		position: static !important;
		text-align: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
	}

	.isotope-item.product {
		position: relative !important;
	}
}

@media (max-width: 479px) {
	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width .isotope-item {
		position: static !important;
		clear: both;
		float: none;
		width: auto;
	}
}
/* Pricing Tables */
.pricing-table {
	margin: 10px 0;
	padding: 0 15px;
	text-align: center;
}

.pricing-table ul {
	list-style: none;
	margin: 20px 0 0 0;
	padding: 0;
}

.pricing-table li {
	border-top: 1px solid #ddd;
	padding: 10px 0;
}

.pricing-table h3 {
	background-color: #eee;
	border-radius: 2px 2px 0 0;
	font-size: 20px;
	font-weight: normal;
	margin: -20px -20px 50px -20px;
	padding: 20px;
}

.pricing-table h3 span {
	background: #FFF;
	border: 5px solid #FFF;
	border-radius: 100px;
	box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
	color: #777777;
	display: block;
	font: bold 25px / 100px Georgia, Serif;
	height: 100px;
	margin: 20px auto -65px;
	width: 100px;
}

.pricing-table .most-popular {
	border: 3px solid #CCC;
	box-shadow: 11px 0 10px -10px rgba(0, 0, 0, 0.1), -11px 0 10px -10px rgba(0, 0, 0, 0.1);
	padding: 30px 20px;
	top: -10px;
	z-index: 2;
}

.pricing-table .most-popular h3 {
	background-color: #666;
	color: #FFF;
	padding-top: 30px;
	text-shadow: 0 1px #555;
}

.pricing-table .plan-ribbon-wrapper {
	height: 88px;
	overflow: hidden;
	position: absolute;
	right: -5px;
	top: -5px;
	width: 85px;
}

.pricing-table .plan-ribbon {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background-image: -webkit-linear-gradient(top, #bfdc7a, #8ebf45);
	background-image: linear-gradient(to bottom, #bfdc7a, #8ebf45);
	background-color: #bfdc7a;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	color: #333;
	font-size: 14px;
	left: -5px;
	padding: 7px 0;
	position: relative;
	text-align: center;
	text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
	top: 15px;
	width: 120px;
}

.pricing-table .plan-ribbon:before {
	left: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
}

.pricing-table .plan-ribbon:after {
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
	right: 0;
}

.pricing-table [class*="col-md-"] {
	padding-left: 0;
	padding-right: 0;
}

.pricing-table .plan {
	background: #FFF;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #333;
	margin-bottom: 35px;
	margin-right: 0;
	padding: 20px;
	position: relative;
	text-shadow: 0 1px rgba(255, 255, 255, 0.8);
}

.pricing-table .btn {
	margin-top: 5px;
}

/* Pricing Tables Boxed */
html.boxed .pricing-table .plan, html.boxed .pricing-table h3 {
	border-radius: 0;
}

/* Responsive */
@media (max-width: 767px) {
	/* Pricing Tables */
	.pricing-table {
		margin-left: 0px;
	}

	.pricing-table .plan {
		margin-right: 0;
	}

	html.boxed .pricing-table {
		margin-left: 0;
	}
}
/* Featured Box */
.featured-box {
	background: #F5F5F5;
	background-color: transparent;
	background: -webkit-linear-gradient(top, white 1%, #f9f9f9 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, white 1%, #f9f9f9 98%) repeat scroll 0 0 transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-radius: 8px;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	min-height: 100px;
	position: relative;
	text-align: center;
	z-index: 1;
}

.featured-box h4 {
	font-size: 1.3em;
	font-weight: 400;
	letter-spacing: -0.7px;
	margin-top: 5px;
	text-transform: uppercase;
}

.featured-box .box-content {
	border-radius: 8px;
	border-top: 3px solid #33B8FF;
	border-top-width: 4px;
	padding: 30px 30px 10px 30px;
}

.featured-box .learn-more {
	display: block;
	margin-top: 10px;
}

/* Featured Boxes */
.featured-boxes .featured-box {
	margin-bottom: 30px;
	margin-top: 45px;
}

.featured-boxes .featured-box.info-content {
	text-align: left;
}

/* Primary */
.featured-box-primary i.icon-featured {
	background-color: #E36159;
}

.featured-box-primary h4 {
	color: #E36159;
}

.featured-box-primary .box-content {
	border-top-color: #E36159;
}

/* Secundary */
.featured-box-secundary i.icon-featured {
	background-color: #CCC;
}

.featured-box-secundary h4 {
	color: #CCC;
}

.featured-box-secundary .box-content {
	border-top-color: #CCC;
}

/* Tertiary */
.featured-box-tertiary i.icon-featured {
	background-color: #2BAAB1;
}

.featured-box-tertiary h4 {
	color: #2BAAB1;
}

.featured-box-tertiary .box-content {
	border-top-color: #2BAAB1;
}

/* Quartenary */
.featured-box-quartenary i.icon-featured {
	background-color: #734BA9;
}

.featured-box-quartenary h4 {
	color: #734BA9;
}

.featured-box-quartenary .box-content {
	border-top-color: #734BA9;
}

/* Featured Boxes - Login */
.featured-boxes.login {
	margin-top: -30px;
}

.featured-boxes.login .featured-box {
	text-align: left;
}

.featured-boxes.cart .featured-box {
	text-align: left;
}

.featured-boxes .featured-box-cart {
	margin-top: 0;
}

/* Feature Box */
.feature-box {
	clear: both;
}

.feature-box .feature-box-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	float: left;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.feature-box .feature-box-info {
	padding-left: 50px;
}

/* Secundary */
.feature-box.secundary h4 {
	color: #0e0e0e;
}

.feature-box.secundary .feature-box-icon {
	background: transparent;
	width: 50px;
	height: 50px;
}

.feature-box.secundary .feature-box-icon i.fa {
	font-size: 28px;
}

.feature-box.secundary .feature-box-info {
	padding-left: 60px;
}

/* Icon Featured */
.icon-featured {
	border-radius: 100%;
	color: #FFF;
	font-size: 40px;
	line-height: 110px;
	margin: 25px 0;
	padding: 25px;
	display: inline-block;
	padding: 0;
	width: 110px;
	height: 110px;
}

/* Thumb Info */
.thumb-info {
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	max-width: 100%;
}

.thumb-info:hover .thumb-info-action-icon {
	right: 0;
	top: 0;
}

.thumb-info:hover .thumb-info-action {
	filter: alpha(opacity=100);
	opacity: 1;
}

.thumb-info:hover .thumb-info-title {
	background: #000;
}

.thumb-info .thumb-info-action-icon {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: #CCC;
	border-radius: 0 0 0 25px;
	display: inline-block;
	font-size: 25px;
	height: 50px;
	line-height: 50px;
	position: absolute;
	right: -100px;
	text-align: center;
	top: -100px;
	width: 50px;
}

.thumb-info .thumb-info-action-icon i {
	font-size: 24px;
	left: 3px;
	position: relative;
	top: -4px;
}

.thumb-info .thumb-info-action {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: rgba(36, 27, 28, 0.9);
	bottom: 0;
	color: #FFF;
	filter: alpha(opacity=0);
	height: 100%;
	left: 0;
	opacity: 0.0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.thumb-info .thumb-info-inner {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	display: block;
	white-space: nowrap;
}

.thumb-info .thumb-info-title {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: rgba(36, 27, 28, 0.9);
	bottom: 10%;
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
	left: 0;
	letter-spacing: -1px;
	padding: 9px 11px 9px;
	position: absolute;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	z-index: 1;
}

.thumb-info .thumb-info-type {
	background-color: #CCC;
	border-radius: 2px;
	display: inline-block;
	float: left;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0;
	margin: 8px -2px -15px -2px;
	padding: 2px 9px;
	text-transform: none;
}

.thumb-info img {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	transition: all 0.2s linear;
	border-radius: 3px;
	position: relative;
}

.thumb-info:hover img {
	-webkit-transform: scale(1.1, 1.1);
	-moz-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

/* Thumb Info Caption	*/
.thumb-info-caption {
	padding: 10px 0;
}

.thumb-info-caption p {
	font-size: 0.9em;
	line-height: 20px;
	margin: 0 0 8px;
	padding: 10px;
}

/* Thumb Info Social Icons */
.thumb-info-social-icons {
	border-top: 1px dotted #DDD;
	margin: 0;
	padding: 15px 0;
	display: block;
}

.thumb-info-social-icons a {
	background: #CCC;
	border-radius: 25px;
	display: inline-block;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.thumb-info-social-icons a:hover {
	text-decoration: none;
}

.thumb-info-social-icons a span {
	display: none;
}

.thumb-info-social-icons a i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Thumb Info Secundary */
.thumb-info.secundary .thumb-info-title {
	background: transparent;
	bottom: auto;
	left: 0;
	right: 0;
	top: 35%;
	text-align: center;
	opacity: 0;
}

.thumb-info.secundary .thumb-info-type {
	float: none;
}

.thumb-info.secundary:hover .thumb-info-title {
	top: 42%;
	opacity: 1;
}

/* Responsive */
@media (max-width: 991px) {
	.thumb-info .thumb-info-title {
		font-size: 14px;
	}

	.thumb-info .thumb-info-more {
		font-size: 11px;
	}
}
/* Timeline */
section.timeline {
	width: 75%;
	margin: 50px 13% 0;
	position: relative;
	float: left;
	padding-bottom: 120px;
}

section.timeline:after {
	background: #505050;
	background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
	background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: linear, to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%;
	content: "";
	display: block;
	height: 100%;
	left: 50%;
	margin-left: -2px;
	position: absolute;
	top: -60px;
	width: 3px;
	z-index: 0;
	filter: alpha(opacity=35);
	opacity: 0.35;
}

section.timeline .timeline-date {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	position: relative;
	display: block;
	clear: both;
	width: 200px;
	height: 45px;
	padding: 5px;
	border: 1px solid #E5E5E5;
	margin: 0 auto 10px;
	text-shadow: 0 1px 1px #fff;
	border-radius: 4px;
	background: #FFF;
	z-index: 1;
}

section.timeline .timeline-date h3 {
	display: block;
	text-align: center;
	color: #757575;
	font-size: 0.9em;
	line-height: 32px;
}

section.timeline .timeline-title {
	background: #F4F4F4;
	padding: 12px;
}

section.timeline .timeline-title h4 {
	padding: 0;
	margin: 0;
	color: #171717;
	font-size: 1.4em;
}

section.timeline .timeline-title a {
	color: #171717;
}

section.timeline .timeline-title .timeline-title-tags {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-title .timeline-title-tags a {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-box {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	margin: 20px 10px 10px 10px;
	padding: 10px;
	position: relative;
	width: 45.7%;
	float: left;
	z-index: 1;
	margin-bottom: 30px;
	background: #FFF;
}

section.timeline .timeline-box.left {
	clear: both;
}

section.timeline .timeline-box.left:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-right: -5px;
	position: absolute;
	right: -6.8%;
	top: 22px;
	width: 8px;
}

section.timeline .timeline-box.left:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-right: 1px solid #E5E5E5;
	border-top: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	right: -8px;
	top: 20px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box.right {
	clear: right;
	float: right;
	right: -1px;
	margin-top: 40px;
}

section.timeline .timeline-box.right:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-left: -5px;
	position: absolute;
	left: -7.2%;
	top: 32px;
	width: 8px;
}

section.timeline .timeline-box.right:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-left: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	left: -8px;
	top: 30px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box .img-thumbnail {
	border-radius: 0;
	padding: 0;
	border: 0;
}

section.timeline .timeline-box .img-thumbnail img {
	border-radius: 0;
	padding: 0;
	border: 0;
}

section.timeline .timeline-box .thumb-info {
	float: left;
	margin-bottom: -5px;
}

section.timeline .timeline-box .owl-carousel {
	border-radius: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
}

/* Responsive */
@media (max-width: 991px) {
	section.timeline .timeline-box {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.right {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.left:after, section.timeline .timeline-box.right:after, section.timeline .timeline-box.left:before, section.timeline .timeline-box.right:before {
		display: none;
	}
}

@media (max-width: 479px) {
	section.timeline {
		width: auto;
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

	section.timeline .timeline-box {
		margin: 15px auto;
		max-width: 90%;
	}

	section.timeline .timeline-box.right {
		margin: 15px auto;
		max-width: 90%;
	}

	article.post-large {
		margin-left: 0;
	}

	article.post-large .post-image, article.post-large .post-date {
		margin-left: 0;
	}
}
/* Testimonials */
blockquote.testimonial {
	background: #CCC;
	border-radius: 10px;
	border: 0;
	color: #666;
	font-family: Georgia, serif;
	font-style: italic;
	margin: 0;
	padding: 10px 50px;
	position: relative;
}

blockquote.testimonial:before {
	left: 10px;
	top: 0;
	color: #FFF;
	content: "\201C";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

blockquote.testimonial:after {
	color: #FFF;
	content: "\201C";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	content: "\201D";
	right: 10px;
}

blockquote.testimonial p {
	color: #FFF;
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
	line-height: 1.3;
}

/* Testimonials Arrow */
.testimonial-arrow-down {
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #CCC;
	height: 0;
	margin: 0 0 0 25px;
	width: 0;
}

/* Testimonials Author */
.testimonial-author {
	margin: 8px 0 0 8px;
}

.testimonial-author .img-thumbnail {
	float: left;
	margin-right: 10px;
	width: auto;
}

.testimonial-author .img-thumbnail img {
	max-width: 60px;
}

.testimonial-author strong {
	color: #111;
	display: block;
	padding-top: 7px;
}

.testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial-author p {
	color: #999;
	margin: 0 0 0 25px;
	text-align: left;
}

/* Testimonials Carousel */
blockquote.testimonial-carousel {
	font-family: Georgia, serif;
	font-style: italic;
	margin: 0;
	padding: 10px 50px;
	position: relative;
}

blockquote.testimonial-carousel:before {
	left: 10px;
	top: 0;
	color: #FFF;
	content: "\201C";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

blockquote.testimonial-carousel:after {
	color: #FFF;
	content: "\201C";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	content: "\201D";
	right: 10px;
}

blockquote.testimonial-carousel p {
	margin: 10px 0;
	color: #dadada;
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
	line-height: 1.3;
}

/* Social Icons */
ul.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
}

ul.social-icons li {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background-image: url("../img/social-sprites.png");
	background-repeat: no-repeat;
	background-position: 0 100px;
	display: inline-block;
	margin: -1px 1px 5px 0;
	padding: 0;
	border-radius: 100%;
	overflow: visible;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

ul.social-icons li a {
	display: block;
	height: 30px;
	width: 30px;
	text-align: center;
}

ul.social-icons li a:hover {
	text-decoration: none;
}

ul.social-icons li a i[class^="icon-"] {
	color: #444;
	font-style: 16px;
	position: relative;
	top: 3px;
}

ul.social-icons li a:active {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 10px rgba(0, 0, 0, 0.3);
}

ul.social-icons li[class] a {
	text-indent: -9999px;
}

ul.social-icons li:active, ul.social-icons li a:active {
	border-radius: 100%;
}

ul.social-icons li.digg {
	background-position: 0 0;
}

ul.social-icons li.digg:hover {
	background-position: 0 -30px;
}

ul.social-icons li.dribbble {
	background-position: 0 -60px;
}

ul.social-icons li.dribbble:hover {
	background-position: 0 -90px;
}

ul.social-icons li.facebook {
	background-position: 0 -120px;
}

ul.social-icons li.facebook:hover {
	background-position: 0 -150px;
}

ul.social-icons li.flickr {
	background-position: 0 -180px;
}

ul.social-icons li.flickr:hover {
	background-position: 0 -210px;
}

ul.social-icons li.forrst {
	background-position: 0 -240px;
}

ul.social-icons li.forrst:hover {
	background-position: 0 -270px;
}

ul.social-icons li.googleplus {
	background-position: 0 -300px;
}

ul.social-icons li.googleplus:hover {
	background-position: 0 -330px;
}

ul.social-icons li.html5 {
	background-position: 0 -360px;
}

ul.social-icons li.html5:hover {
	background-position: 0 -390px;
}

ul.social-icons li.icloud {
	background-position: 0 -420px;
}

ul.social-icons li.icloud:hover {
	background-position: 0 -450px;
}

ul.social-icons li.lastfm {
	background-position: 0 -480px;
}

ul.social-icons li.lastfm:hover {
	background-position: 0 -510px;
}

ul.social-icons li.linkedin {
	background-position: 0 -540px;
}

ul.social-icons li.linkedin:hover {
	background-position: 0 -570px;
}

ul.social-icons li.myspace {
	background-position: 0 -600px;
}

ul.social-icons li.myspace:hover {
	background-position: 0 -630px;
}

ul.social-icons li.paypal {
	background-position: 0 -660px;
}

ul.social-icons li.paypal:hover {
	background-position: 0 -690px;
}

ul.social-icons li.picasa {
	background-position: 0 -720px;
}

ul.social-icons li.picasa:hover {
	background-position: 0 -750px;
}

ul.social-icons li.pinterest {
	background-position: 0 -780px;
}

ul.social-icons li.pinterest:hover {
	background-position: 0 -810px;
}

ul.social-icons li.reddit {
	background-position: 0 -840px;
}

ul.social-icons li.reddit:hover {
	background-position: 0 -870px;
}

ul.social-icons li.rss {
	background-position: 0 -900px;
}

ul.social-icons li.rss:hover {
	background-position: 0 -930px;
}

ul.social-icons li.skype {
	background-position: 0 -960px;
}

ul.social-icons li.skype:hover {
	background-position: 0 -990px;
}

ul.social-icons li.stumbleupon {
	background-position: 0 -1020px;
}

ul.social-icons li.stumbleupon:hover {
	background-position: 0 -1050px;
}

ul.social-icons li.tumblr {
	background-position: 0 -1080px;
}

ul.social-icons li.tumblr:hover {
	background-position: 0 -1110px;
}

ul.social-icons li.twitter {
	background-position: 0 -1140px;
}

ul.social-icons li.twitter:hover {
	background-position: 0 -1170px;
}

ul.social-icons li.vimeo {
	background-position: 0 -1200px;
}

ul.social-icons li.vimeo:hover {
	background-position: 0 -1230px;
}

ul.social-icons li.wordpress {
	background-position: 0 -1260px;
}

ul.social-icons li.wordpress:hover {
	background-position: 0 -1290px;
}

ul.social-icons li.yahoo {
	background-position: 0 -1320px;
}

ul.social-icons li.yahoo:hover {
	background-position: 0 -1350px;
}

ul.social-icons li.youtube {
	background-position: 0 -1380px;
}

ul.social-icons li.youtube:hover {
	background-position: 0 -1410px;
}

ul.social-icons li.github {
	background-position: 0 -1440px;
}

ul.social-icons li.github:hover {
	background-position: 0 -1470px;
}

ul.social-icons li.behance {
	background-position: 0 -1500px;
}

ul.social-icons li.behance:hover {
	background-position: 0 -1530px;
}

ul.social-icons li.yelp {
	background-position: 0 -1560px;
}

ul.social-icons li.yelp:hover {
	background-position: 0 -1590px;
}

ul.social-icons li.mail {
	background-position: 0 -1620px;
}

ul.social-icons li.mail:hover {
	background-position: 0 -1650px;
}

ul.social-icons li.instagram {
	background-position: 0 -1680px;
}

ul.social-icons li.instagram:hover {
	background-position: 0 -1710px;
}

ul.social-icons li.foursquare {
	background-position: 0 -1740px;
}

ul.social-icons li.foursquare:hover {
	background-position: 0 -1770px;
}

ul.social-icons li.zerply {
	background-position: 0 -1800px;
}

ul.social-icons li.zerply:hover {
	background-position: 0 -1830px;
}

ul.social-icons li.vk {
	background-position: 0 -1860px;
}

ul.social-icons li.vk:hover {
	background-position: 0 -1890px;
}

/* Arrows */
.arrow {
	background: transparent url("../img/arrows.png") no-repeat 0 0;
	width: 47px;
	height: 120px;
	display: inline-block;
	position: relative;
}

.arrow.vtl {
	background-position: 0 0;
	width: 47px;
	height: 96px;
}

.arrow.vtr {
	background-position: -101px 0;
	width: 47px;
	height: 96px;
}

.arrow.vbl {
	background-position: 0 -144px;
	width: 47px;
	height: 96px;
}

.arrow.vbr {
	background-position: -101px -144px;
	width: 47px;
	height: 96px;
}

.arrow.hlt {
	background-position: -209px 0;
	width: 120px;
	height: 47px;
}

.arrow.hlb {
	background-position: -209px -101px;
	width: 120px;
	height: 47px;
}

.arrow.hrt {
	background-position: -353px 0;
	width: 120px;
	height: 47px;
}

.arrow.hrb {
	background-position: -353px -101px;
	width: 120px;
	height: 47px;
}

/* Responsive */
@media (max-width: 479px) {
	.arrow {
		display: none;
	}
}
/* Circular Bars */
.circular-bar {
	margin-bottom: 25px;
}

.circular-bar .circular-bar-chart {
	position: relative;
}

.circular-bar strong {
	display: block;
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	position: absolute;
	top: 35%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar label {
	display: block;
	font-weight: 100;
	font-size: 17px;
	line-height: 20px;
	position: absolute;
	top: 50%;
	width: 80%;
	left: 10%;
	text-align: center;
}

/* Progress */
.progress {
	border-radius: 25px;
	height: 20px;
	background: #FAFAFA;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
	overflow: visible;
}

.progress-bar {
	background-color: #CCC;
	box-shadow: none;
	position: relative;
	border-radius: 25px;
}

.progress-bar-tooltip {
	position: absolute;
	padding: 4px 8px;
	background-color: #333;
	color: #FFF;
	line-height: 15px;
	font-size: 11px;
	display: block;
	position: absolute;
	top: -28px;
	right: 5px;
	border-radius: 3px;
	filter: alpha(opacity=0);
	opacity: 0;
}

.progress-bar-tooltip:after {
	border-color: #333 transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	bottom: -5px;
	content: "";
	display: block;
	left: 13px;
	position: absolute;
	width: 0;
}

/* Counters */
.counters .fa {
	display: block;
	font-size: 35px;
	line-height: 35px;
	margin-bottom: 5px;
}

.counters strong {
	display: block;
	font-weight: 600;
	font-size: 60px;
	line-height: 60px;
}

.counters label {
	display: block;
	font-weight: 100;
	font-size: 20px;
	line-height: 20px;
}

.counters.dark .fa {
	color: #333;
}

.counters.dark strong {
	color: #333;
	font-size: 50px;
	line-height: 50px;
}

/* Word Rotate */
.word-rotate {
	display: inline-block;
	overflow: hidden;
	text-align: center;
	position: relative;
	max-height: 22px;
	line-height: 22px;
}

.word-rotate .word-rotate-items {
	position: relative;
	top: 0;
	width: 0;
	display: inline-block;
}

.word-rotate .word-rotate-items span {
	display: inline-block;
	white-space: nowrap;
}

.word-rotate .word-rotate-items span:not(:first-child) {
	display: none;
}

.word-rotate.active .word-rotate-items {
	position: relative;
}

.word-rotate.active .word-rotate-items span {
	display: inline-block;
}

h1.word-rotator-title .inverted {
	min-height: 56px;
}

h1.word-rotator-title .word-rotate {
	max-height: 46px;
	line-height: 46px;
	margin-bottom: -9px;
}

h2.word-rotator-title .inverted {
	min-height: 46px;
}

h2.word-rotator-title .word-rotate {
	max-height: 42px;
	line-height: 42px;
	margin-bottom: -9px;
}

h3.word-rotator-title .inverted {
	min-height: 25px;
}

h3.word-rotator-title .word-rotate {
	max-height: 24px;
	line-height: 24px;
	margin-bottom: -2px;
}

h4.word-rotator-title .inverted {
	min-height: 32px;
}

h4.word-rotator-title .word-rotate {
	max-height: 27px;
	line-height: 27px;
	margin-bottom: -6px;
}

h5.word-rotator-title .inverted {
	min-height: 18px;
}

h5.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

h6.word-rotator-title .inverted {
	min-height: 20px;
}

h6.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

p .inverted {
	min-height: 24px;
}

p .word-rotate {
	max-height: 22px;
	line-height: 22px;
	margin-bottom: -6px;
}

/* Scroll to Top */
html .scroll-to-top {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: #404040;
	border-radius: 7px 7px 0 0;
	bottom: 0px;
	color: #FFF;
	display: block;
	height: 9px;
	opacity: 0;
	padding: 13px 10px 35px;
	position: fixed;
	right: 10px;
	text-align: center;
	text-decoration: none;
	min-width: 49px;
	z-index: 1040;
}

html .scroll-to-top:hover {
	filter: alpha(opacity=100);
	opacity: 1;
}

html .scroll-to-top.visible {
	filter: alpha(opacity=75);
	opacity: 0.75;
}

html .scroll-to-top span {
	display: inline-block;
	padding: 0 5px;
}

html.ie11 .scroll-to-top {
	right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
	html .scroll-to-top.hidden-mobile {
		display: none !important;
	}
}
/* Portfolio */
.portfolio-title {
	margin-bottom: -10px;
}

.portfolio-title a {
	display: inline-block;
}

.portfolio-title .fa {
	font-size: 2.5em;
}

.portfolio-title .portfolio-nav,
.portfolio-title .portfolio-nav-all {
	margin-top: 11px;
}

.portfolio-title .portfolio-nav {
	text-align: right;
}

.portfolio-title .portfolio-nav a {
	width: 40%;
}

.portfolio-title .portfolio-nav-all .fa {
	font-size: 2.7em;
}

.portfolio-title .fa-angle-right {
	text-align: right;
}

.portfolio-title .portfolio-nav-prev {
	text-align: left;
}

/* Portfolio Info */
.portfolio-info {
	margin: 2px 0 10px;
	float: right;
}

.portfolio-info ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.portfolio-info ul li {
	display: inline-block;
	border-right: 1px solid #E6E6E6;
	color: #B1B1B1;
	padding-right: 8px;
	margin-right: 8px;
	font-size: 0.9em;
}

.portfolio-info ul li:last-child {
	border-right: 0;
	padding-right: 0;
	margin-right: 0;
}

.portfolio-info ul li a {
	text-decoration: none;
	color: #B1B1B1;
}

.portfolio-info ul li a:hover .fa-heart {
	color: #E53F51;
}

.portfolio-info ul li .fa {
	margin-right: 4px;
}

/* Portfolio List */
ul.portfolio-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.portfolio-list .portfolio-item {
	margin-bottom: 35px;
}

/* Portfolio Details */
ul.portfolio-details {
	list-style: none;
	padding: 0;
	margin: 45px 0 0 5px;
	font-size: 0.9em;
}

ul.portfolio-details li {
	padding: 0;
	margin: 0 0 20px;
}

ul.portfolio-details p {
	padding: 0;
	margin: 0;
}

ul.portfolio-details blockquote {
	padding: 0 0 0 10px;
	margin: 15px 0 0;
	color: #999;
}

ul.portfolio-details ul.list-skills li {
	margin-right: 10px;
	margin-bottom: 5px;
}

/* Responsive */
@media (max-width: 991px) {
	.portfolio-nav, .portfolio-nav-all {
		display: none;
	}
}
/* Team List */
ul.team-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.team-list .team-item {
	margin-bottom: 35px;
}

ul.team-list .team-item .thumb-info-social-icons {
	margin: 0 10px;
}

/* Responsive */
@media (max-width: 991px) {
	ul.team-list .team-item {
		margin: 0 auto 35px;
		max-width: 270px;
	}
}
/* History */
ul.history {
	margin: 0 0 25px;
	padding: 0;
	list-style: none;
}

ul.history li {
	margin: 25px 0;
	padding: 0;
	clear: both;
}

ul.history li h4 {
	margin: 0 0 -27px 0;
	padding: 0;
}

ul.history li p {
	margin-left: 53px;
}

ul.history li .thumb {
	background: transparent url("../img/history-thumb.png") no-repeat 0 0;
	width: 227px;
	height: 160px;
	margin: 0 0 30px;
	padding: 0;
	float: left;
}

ul.history li .thumb img {
	border-radius: 150px;
	margin: 7px 8px;
	width: 145px;
	height: 145px;
}

ul.history li .featured-box {
	text-align: left;
	margin-left: 225px;
	margin-bottom: 50px;
	min-height: 115px;
}

ul.history li .featured-box .box-content {
	border-top-color: #CCC;
}

/* Responsive */
@media (max-width: 479px) {
	ul.history li .thumb {
		display: none;
	}

	ul.history li .featured-box {
		margin-left: 0;
	}
}
/* Search Results */
.search-results {
	min-height: 300px;
}

/* Maps */
.google-map {
	background: #E5E3DF;
	height: 400px;
	margin: -35px 0 30px 0;
	width: 100%;
}

.google-map img {
	max-width: 9999px;
}

/* Maps - Parallax */
.parallax + .google-map {
	margin-top: -70px;
}


